import { env } from './EnvironmentConfig';

export const APP_NAME = 'Oumuamua';
//REACT_APP_DEV_MODE values - "Dev" or "Live"
export const REACT_APP_DEV_MODE = "Live";     //also change reCAPTCHA in public.html

export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '';
export const APP_IN_PRODUCTION = process.env.NODE_ENV === 'production';


export const TEST_URL = "https://design.24liveyard.com/";
export const LIVE_URL = "https://oumuamua.news/";
export const DONATE_LINK = "https://oumuamua.networkforgood.com/projects/233397-everyday-giving"
